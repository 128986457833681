export const useGetFormattedDuration = (duration) => {
  const { t } = useI18n()

  const durationText = convertMinutesToHumanString(duration)

  if (durationText === '') {
    return t('product.card.durationDefaultContent')
  }

  return durationText
}
