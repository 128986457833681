const FEATURE_TOGGLE_KEY = 'feature-toggles'

type FeatureStatus = 'enabled' | 'disabled'

type FeatureToggles = {
  [key: string]: FeatureStatus
}

export const useFeatureToggle = () => {
  const features = useKftLocalStorage<FeatureToggles>(FEATURE_TOGGLE_KEY, {})

  const isFeatureEnabled = (feature: string) =>
    features.value?.[feature] === 'enabled'

  return {
    features,
    isFeatureEnabled,
  }
}
