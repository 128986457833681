<template>
  <div ref="horizontalMobileScrollWrapper">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
// Taken from https://stackoverflow.com/a/73184878
// With additional improvements by Guilherme.
const horizontalMobileScrollWrapper = ref<HTMLElement | null>(null)

const isPointerMoving = ref(false)

const pointerFrom = ref(0)
const elementFrom = ref(0)

const onClick = (event: MouseEvent) => {
  if (isPointerMoving.value) {
    // Prevent the click event from being triggered if the pointer is moving.
    event.stopPropagation()
    event.preventDefault()
    return false
  }
}

const onDrag = (event: PointerEvent) => {
  const scrollable = horizontalMobileScrollWrapper.value

  // Ensure we only do this for pointers that don't have native
  // drag-scrolling behavior and when the pointer is down.
  if (event.pointerType === 'mouse') {
    scrollable!.scrollLeft =
      elementFrom.value - event.clientX + pointerFrom.value
  }
  isPointerMoving.value = true
}

const onPointerUp = (event: PointerEvent) => {
  const scrollable = horizontalMobileScrollWrapper.value

  setTimeout(() => {
    // A small delay to ensure the click event is not triggered if the pointer is moving.
    isPointerMoving.value = false
  }, 100)
  // Ensure we only do this for pointers that don't have native
  // drag-scrolling behavior.
  if (event.pointerType === 'mouse') {
    document.removeEventListener('pointermove', onDrag)
  }
}

const onPointerDown = (event: PointerEvent) => {
  const scrollable = horizontalMobileScrollWrapper.value

  // Ensure we only do this for pointers that don't have native
  // drag-scrolling behavior.
  if (event.pointerType === 'mouse') {
    // pointerDown = true;
    // Set the position where the mouse is starting to drag from.
    pointerFrom.value = event.clientX
    // Set the position of the element is scrolled from.
    elementFrom.value = scrollable!.scrollLeft

    // React on pointer move.
    document.addEventListener('pointermove', onDrag)
  }
}

const setScrollable = () => {
  if (!horizontalMobileScrollWrapper.value) {
    return
  }

  const scrollable = horizontalMobileScrollWrapper.value

  scrollable.addEventListener('pointerdown', (event) => onPointerDown(event))
  scrollable.addEventListener('click', onClick, true)

  // Stop reacting on pointer move when pointer is no longer clicked.
  document.addEventListener('pointerup', onPointerUp)
}

const removeEventListeners = () => {
  horizontalMobileScrollWrapper.value?.removeEventListener(
    'click',
    onClick,
    true
  )
  horizontalMobileScrollWrapper.value?.removeEventListener(
    'pointerdown',
    onPointerDown
  )

  document.removeEventListener('pointerup', onPointerUp)
  document.removeEventListener('pointermove', onDrag)
}
onMounted(() => {
  setScrollable()
})

onUnmounted(() => {
  removeEventListeners()
})
</script>
