<template>
  <div class="kft-product-card-wrapper" data-test-id="product-card">
    <NuxtLinkLocale v-slot="{ route, href }" :to="link" custom>
      <a
        class="select-none"
        draggable="false"
        :href="href"
        :target="openInNewTab ? '_blank' : '_self'"
        @click.prevent="
          handleClick ? $emit('click', route) : navigateToRoute(route)
        "
      >
        <div class="kft-product-card">
          <div>
            <!--BEGIN::IMAGE WRAPPER  -->
            <div class="kft-product-card__image-wrapper">
              <slot
                name="image"
                v-bind="{
                  image,
                  title,
                  link,
                  imageHeight,
                  imageWidth,
                  alt,
                  badgeLabel,
                }"
              >
                <div
                  class="kft-button--pure kft-product-card__link overflow-hidden rounded-md"
                >
                  <img
                    v-if="image"
                    :alt="alt || title"
                    :src="image"
                    :loading="imageLoading"
                    draggable="false"
                    class="kft-product-card__image rounded-md object-cover hover:scale-105 transition ease-in-out duration-500"
                  />
                  <div
                    v-else
                    class="kft-product-card__image bg-gray-200 rounded-md"
                  ></div>
                  <Chip
                    v-if="badgeLabel"
                    classes="absolute top-1 left-1"
                    variant="card"
                  >
                    {{ badgeLabel }}
                  </Chip>
                </div>
              </slot>
            </div>
            <!-- END::IMAGE WRAPPER -->

            <!-- BEGIN::TITLE -->
            <div
              v-if="!$slots.title"
              :title="title"
              class="font-normal pt-3 text-[14.88px] leading-4"
              data-test-id="kft-product-card-title"
            >
              {{ title }}
            </div>
            <slot v-else name="title" v-bind="{ title, link }">
              <div
                :title="title"
                class="kft-button--pure kft-product-card__link"
              >
                <span class="kft-product-card__title">
                  {{ title }}
                </span>
              </div>
            </slot>
            <!-- END::TITLE -->
          </div>
          <div>
            <!-- BEGIN::QUICK ICONS -->
            <div class="kft-product-card__icons-wrapper">
              <slot name="duration" v-bind="{ duration, rating }">
                <div v-if="isDurationVisible" class="flex items-center">
                  <IconClock class="w-5 h-5 mt-1 -mr-0.5" />
                  <span class="pl-1">{{ duration }}</span>
                </div>
                <div v-if="rating !== null" class="flex items-center">
                  <IconStar class="w-5 h-5 mt-1 -mr-0.5" />
                  <span class="pl-1">
                    {{ rating || $t('product.reviews.newEventLabel') }}
                  </span>
                </div>
              </slot>
            </div>
            <!-- END::QUICK ICONS -->

            <span v-if="isFeatureEnabled('next-date-on-product-card')">
              {{ nextEventDateTime }}
            </span>

            <!-- BEGIN::PRICE -->
            <slot name="price" v-bind="{ specialPrice, regularPrice }">
              <Price
                v-if="regularPrice"
                :regular="regularPrice"
                :size="16"
                :special="specialPrice"
                class="kft-product-card__price"
              />
            </slot>
            <!-- END::PRICE -->
          </div>
        </div>
        <slot></slot>
      </a>
    </NuxtLinkLocale>
    <!-- </nuxt-link> -->
  </div>
</template>
<script>
import Price from '../common/Price'
import IconClock from '~/components/icons/IconClock'
import IconStar from '~/components/icons/IconStar'

export default {
  components: {
    Price,
    IconClock,
    IconStar,
  },
  props: {
    openInNewTab: {
      type: Boolean,
      default: false,
    },
    image: {
      type: [Array, Object, String],
      default: '',
    },
    imageWidth: {
      type: [String, Number],
      default: 270,
    },
    imageHeight: {
      type: [String, Number],
      default: 330,
    },
    imageLoading: {
      type: String,
      default: 'lazy',
      validator: (value) => ['', 'lazy', 'eager'].includes(value),
    },
    badgeLabel: {
      type: String,
      default: '',
    },
    productType: {
      type: String,
      default: '',
    },
    duration: {
      type: String,
      default: '',
    },
    durationTitle: {
      type: String,
      default: '',
    },
    isDurationVisible: {
      type: Boolean,
      default: true,
    },
    rating: {
      type: [Number, String],
      default: null,
    },
    nextEventDateTime: {
      type: String,
      default: '',
    },
    productCategory: {
      type: String,
      default: '',
    },
    badgeColor: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: '',
    },
    linkTag: {
      type: String,
      default: undefined,
    },
    scoreRating: {
      type: [Number, Boolean],
      default: false,
    },
    handleClick: {
      type: Boolean,
      default: false,
    },
    reviewsCount: {
      type: [Number, Boolean],
      default: false,
    },
    maxRating: {
      type: [Number, String],
      default: 5,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    wishlistIcon: {
      type: [String, Array, Boolean],
      default: 'heart',
    },
    isOnWishlistIcon: {
      type: [String, Array],
      default: 'heart_fill',
    },
    isOnWishlist: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],

  setup(props) {
    const $router = useRouter()
    const { isFeatureEnabled } = useFeatureToggle()
    const navigateToRoute = (route) => {
      if (props.openInNewTab) {
        window.open(route.path, '_blank')
        return
      }
      $router.push(route)
    }

    return {
      navigateToRoute,
      isFeatureEnabled,
    }
  },

  computed: {
    currentWishlistIcon() {
      return this.isOnWishlist ? this.isOnWishlistIcon : this.wishlistIcon
    },
    ariaLabel() {
      return this.isOnWishlist ? 'Remove from wishlist' : 'Add to wishlist'
    },
    wishlistIconClasses() {
      const defaultClass = 'kft-button--pure kft-product-card__wishlist-icon'
      return `${defaultClass} ${this.isOnWishlist ? 'on-wishlist' : ''}`
    },
  },
}
</script>
<style lang="postcss" scoped>
.kft-product-card-wrapper a {
  color: black;
  display: block;
  height: 100%;
}
.kft-product-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: var(--product-card-z-index);
  position: relative;
}
.kft-product-card i {
  font-size: var(--product-card-icons-font-size, 12px);
  margin: var(--product-card-icons-margin, 0 var(--spacer-1) 0 0);
}
.kft-product-card.loading {
  background-color: unset !important;
}
.kft-product-card::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.kft-product-card:hover {
  --product-card-image-opacity: 1;
  --product-card-add-button-opacity: 1;
  --product-card-z-index: 1;
}
.kft-product-card:active {
  --image-box-shadow: unset;
}
.kft-product-card__chips {
  bottom: -10px;
  left: 5px;
  position: absolute;
  opacity: var(--product-card-category-chips-opacity);
}
.kft-product-card__link {
  display: block;
  width: 100%;
  line-height: 0;
  text-decoration: none;
  margin: var(--product-card-link-margin, 0);
  text-align: left;
}
.kft-product-card__icons-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  @apply py-0.5;
}
.kft-product-card__title {
  color: black;
  margin: var(--product-card-title-margin, var(--spacer-3) 0 var(--spacer-1));
  /* Elipsis */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.kft-product-card__image-wrapper {
  position: relative;
}
.kft-product-card__image,
.kft-product-card__picture {
  box-shadow: var(--image-box-shadow);
  opacity: var(--product-card-image-opacity);
  width: 100%;
  aspect-ratio: 1.3333333333;
  overflow: hidden;
}
</style>
