<template>
  <div class="kft-product-showcase">
    <ErrorBoundary>
      <div class="flex flex-col">
        <!-- Begin::Title -->
        <Heading
          v-if="title"
          :h-class="titleHClass || `h${titleLevel}`"
          :level="titleLevel"
          :title="title"
          :use-h-tag="useHTag"
          class="mb-8 mb-md-4 kft-product-showcase__heading kft-heading--left"
        />
        <!-- End::Title -->

        <component
          :is="mobileHorizontalScroll ? 'HorizontalMobileScrollWrapper' : 'div'"
          class="product-showcase-cards-container"
          :class="{
            'product-showcase-cards-container--mobile-horizontal-scroll':
              mobileHorizontalScroll,
          }"
          data-test-id="search-results"
        >
          <template
            v-for="(item, i) in products"
            :key="`kft-search-product-card-${item.objectID}`"
          >
            <ProductCard
              :data-insights-object-id="item.objectID"
              :duration="
                fnGetFormattedDuration(algoliaEventGetters.fnGetDuration(item))
              "
              :duration-title="$t('product.card.duration')"
              :image="algoliaEventGetters.getPrincipalImage(item)"
              :image-loading="imageLoading"
              :next="null"
              :link="algoliaEventGetters.fnGetLink(item)"
              :product-category="algoliaEventGetters.fnGetMainCategory(item)"
              :product-type="''"
              :badge-label="algoliaEventGetters.getBadgeLabel(item, $t)"
              :rating="algoliaEventGetters.getAverageRating(item, localeBCP47)"
              :regular-price="
                formatPriceAmount(
                  algoliaEventGetters.getPriceAmount(item),
                  locale
                )
              "
              :show-add-to-cart-button="false"
              :style="{ '--index': i }"
              :title="item.title || 'simple-event-showcase'"
              :alt="algoliaEventGetters.getPrincipalAlt(item)"
              :wishlist-icon="false"
              :is-duration-visible="isDurationVisible"
              class="products__product-card overflow-hidden transition-all duration-300 ease-in-out"
              :class="{
                'lg:max-h-0': i >= notCollapsedAmountLg && isCollapsed,
                'md:max-h-0': i >= notCollapsedAmountMd && isCollapsed,
                'lg:max-h-[30rem]': i < notCollapsedAmountLg,
                'max-h-[30rem]': !isCollapsed,
              }"
              handle-click
              @click="fnHandleClickOnCard(item, i)"
            />

            <ProductCard
              v-if="i === 0 && specialGiftcardEvent"
              :data-test-id="'product-card-giftcard'"
              :duration="
                fnGetFormattedDuration(
                  algoliaEventGetters.fnGetDuration(specialGiftcardEvent)
                )
              "
              :image="
                algoliaEventGetters.getPrincipalImage(specialGiftcardEvent)
              "
              :link="
                localePath(
                  `/giftcard/${algoliaEventGetters.fnGetPermalink(
                    specialGiftcardEvent
                  )}/`
                )
              "
              :product-category="
                algoliaEventGetters.fnGetMainCategory(specialGiftcardEvent)
              "
              :regular-price="
                algoliaEventGetters.fnGetPrice(specialGiftcardEvent)
              "
              :rating="
                algoliaEventGetters.getAverageRating(
                  specialGiftcardEvent,
                  localeBCP47
                )
              "
              :title="specialGiftcardEvent.title || 'No title'"
              :wishlist-icon="false"
              :is-duration-visible="false"
            />
            <template v-for="(position, index) in promotionSlotIndices">
              <slot
                v-if="i === position"
                :name="`promotion${index + 1}`"
              ></slot>
            </template>
          </template>
        </component>
        <Button
          v-show="
            collapseProducts && products.length - notCollapsedRows * 5 > 0
          "
          class="w-[300px] h-[45px] self-center mt-4 hidden md:block"
          @click="isCollapsed = !isCollapsed"
          >{{
            isCollapsed
              ? $t('product.showcase.showMore')
              : $t('product.showcase.showLess')
          }}
        </Button>
      </div>
      <!-- End::Product List -->
    </ErrorBoundary>
  </div>
</template>

<script lang="ts">
import { twMerge } from 'tailwind-merge'
import { type AlgoliaEventItem } from '~/composables/types/algolia'
import { sendViewItemList } from '~/composables/useGtmEvents'
import { HorizontalMobileScrollWrapper } from '#components'

export default {
  name: 'ProductShowcase',
  components: {
    HorizontalMobileScrollWrapper,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    queryId: {
      type: [Array, String],
      default: '',
    },
    titleLevel: {
      type: Number,
      default: 2,
    },
    titleHClass: {
      type: String,
      default: 'text-h3 font-semibold',
    },
    tags: {
      type: Array,
      default: () => [],
    },
    products: {
      type: Array,
      default: () => [],
    },
    imageLoading: {
      type: String,
      default: 'lazy',
      validator: (value) => ['', 'lazy', 'eager'].includes(value),
    },
    promotionSlotIndices: {
      type: Array,
      default: () => [2, 10],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disableSendEventToAlgolia: {
      type: Boolean,
      default: false,
    },
    algoliaInsightsLabel: {
      type: String,
      default: '',
    },
    gtmItemListId: {
      type: String,
      default: null,
    },
    algoliaInsightsClient: {
      type: [Object, Function, null],
      default: () => {},
    },
    giftCardEvent: {
      type: Boolean,
      default: false,
    },
    collapseProducts: {
      type: Boolean,
      default: false,
    },
    mobileHorizontalScroll: {
      type: Boolean,
      default: false,
    },
    cardSkeletonAmount: {
      type: Number,
      default: undefined,
    },
    notCollapsedRows: {
      type: Number,
      default: 1,
    },
    useHTag: {
      type: Boolean,
      default: true,
    },
    specialGiftcardEvent: {
      type: Object,
      default: undefined,
    },
    isDurationVisible: {
      type: Boolean,
      default: true,
    },
  },
  emits: {},
  setup(props) {
    const uid = useId()
    const router = useRouter()
    const route = useRoute()
    const { t, locale } = useI18n()
    //   const { sendViewItemList, sendSelectListItem } = useGtm();
    const fnGetFormattedDuration = (d) => useGetFormattedDuration(d)

    const shouldRunSendViewItemList = ref(false)
    const localeBCP47 = convertLocaleIntoBCP47(locale.value)

    const isCollapsed = ref(props.collapseProducts)

    const notCollapsedAmountLg = computed(() => {
      return props.notCollapsedRows * 5
    })

    const notCollapsedAmountMd = computed(() => {
      return props.notCollapsedRows * 5 - props.notCollapsedRows
    })

    const computedCardSkeletonAmount = computed(() => {
      if (props.cardSkeletonAmount) {
        return props.cardSkeletonAmount
      }

      if (props.collapseProducts) {
        return notCollapsedAmountLg.value
      }

      return 5
    })

    const internalProducts = computed(() => {
      if (isCollapsed.value && props.collapseProducts) {
        return props.products.slice(0, 5)
      }

      return props.products
    })

    const fnSendEventToAlgolia = (route, objectID: string, position) => {
      if (props.disableSendEventToAlgolia) {
        return
      }
      if (!window?.localStorage) {
        return
      }
      let token
      try {
        token = window?.localStorage?.getItem(
          'konfetti-algolia-tracking-user-token'
        )
      } catch (error) {
        console.error('Error accessing local storage', error)
      }

      if (!token || token === 'undefined') {
        token = uuid4()
      }

      if (
        props.algoliaInsightsClient !== null &&
        props.algoliaInsightsClient !== undefined &&
        token
      ) {
        props.algoliaInsightsClient('clickedObjectIDs', {
          userToken: token,
          index: getEventListIndexName(locale.value),
          eventName: 'Event Clicked - ' + props.algoliaInsightsLabel,
          objectIDs: [objectID],
          positions: [position],
          queryId: props.queryId,
        })
      }
    }

    const routerPushToEvent = (object: AlgoliaEventItem, queryId: string) => {
      router.push(
        algoliaEventGetters.getRouterPushToEvent(
          object,
          queryId,
          locale.value,
          route.query.layout === 'sup'
        )
      )
    }

    const fnHandleClickOnCard = (item: AlgoliaEventItem, i: number) => {
      fnSendEventToAlgolia(
        algoliaEventGetters.fnGetLink(item),
        item.objectID,
        i + 1
      )

      routerPushToEvent(item, props.queryId)
      // TODO [GTM] sendSelectListItem(props.gtmItemListId, [item], i);
    }

    onMounted(() => {
      // This would trigger the sendViewItemList event if the products
      // were loaded on server-side but these are not supposed to be seen
      // if (shouldRunSendViewItemList.value) {
      //   sendViewItemList(props.gtmItemListId, props.products)
      // }
    })

    watch(
      () => props.products,
      (newValue) => {
        if (newValue && newValue.length > 0 && props.gtmItemListId) {
          if (process.client) {
            sendViewItemList(props.gtmItemListId, props.products)
          } else {
            shouldRunSendViewItemList.value = true
          }
        }
      },
      { immediate: true }
    )

    return {
      uid,
      locale,
      localeBCP47,
      t,
      isCollapsed,
      internalProducts,
      notCollapsedAmountLg,
      notCollapsedAmountMd,
      computedCardSkeletonAmount,
      fnGetFormattedDuration,
      fnSendEventToAlgolia,
      fnHandleClickOnCard,
      filteredProducts: computed(() =>
        props.products.filter(
          (item) => algoliaEventGetters.fnGetMaximumPriceAmount(item) > 0
        )
      ),
    }
  },
  methods: { twMerge },
}
</script>

<style lang="postcss">
.product-showcase-cards-container {
  @apply grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 gap-2;
}

.product-showcase-cards-container--mobile-horizontal-scroll {
  @apply w-screen grid-flow-col auto-cols-auto overflow-x-scroll
  grid-cols-none px-4 relative
  md:px-0 md:overflow-auto md:w-auto md:static md:grid-flow-dense
  md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5;
  scrollbar-width: none;
  -ms-overflow-style: none;
  left: -1rem;
}

.product-showcase-cards-container--mobile-horizontal-scroll > * {
  width: calc(100vw / 2 - 2rem);
  overflow: hidden;
  @apply md:w-auto;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  //position: absolute;
}
</style>
